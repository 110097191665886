import { Routes } from '@angular/router';
import { ParteModule } from 'src/app/module/publico/parte/parte.module';
import { CadastroComponent } from 'src/app/module/publico/parte/cadastro/cadastro.component';


export const PublicLayout_ROUTES: Routes = [
    {
        path: '',
        component: CadastroComponent
     }
];