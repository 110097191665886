<nz-card>
    <div class="media align-items-center m-b-25">
        <nz-avatar nzIcon="user-add" [nzSize]="50" style="color:#ffffff; background-color:#3f87f5"></nz-avatar>
        <div class="media-body m-l-15">
            <h4 nz-title class="mb-0">Cadastre-se</h4>
        </div>
    </div>
    <form nz-form nzLayout="vertical" [formGroup]="parteForm" class="mb-4" novalidate>
        <div class="row">
            <div class="col-lg-6">
                <div class="checkbox">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Campo obrigatório">
                            <nz-form-label nzRequired>CPF</nz-form-label>
                            <input nz-input id="cpf" 
                                [dropSpecialCharacters]="true" mask="000.000.000-00"
                                formControlName="cpf" placeholder="Informe o CPF" (ngModelChange)="verificarSeAdvogado($event)" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </div>
        <nz-spin *ngIf="loadingVerificacaoCPF" class="text-center" nzSimple></nz-spin>
        <div *ngIf="this.parteForm.controls.advogado.value == 1 && this.parteForm.controls.cpf.valid && !loadingVerificacaoCPF"> <!-- Advogado -->
            <div *ngIf="!this.parteForm.controls.validado.value">
                <div class="row">
                    <div class="col-lg-6">
                        <div>
                            <nz-form-item>
                                <nz-form-control nzErrorTip="Campo obrigatório">
                                    <nz-form-label nzRequired>E-mail de confirmação</nz-form-label>
                                    <nz-select 
                                        id="emailConfirmacao"
                                        formControlName="emailConfirmacao"
                                        nzShowSearch 
                                        nzAllowClear 
                                        nzPlaceHolder="Selecione o e-mail de confirmação"
                                        [nzLoading]="emailConfirmacaoLoading"
                                        (ngModelChange)="iniciarValidacaoEmailAdvogado($event, this.parteForm.controls.cpf.value)">
                                    
                                        <nz-option *ngFor="let email of emailsParaConfirmacao" 
                                            [nzLabel]="email" [nzValue]="email">
                                        </nz-option>     
                                    
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="checkbox" *ngIf="this.parteForm.controls.emailAnonimatizado.value.length>0">
                            <nz-form-item>
                                <nz-form-control nzErrorTip="Campo obrigatório">
                                    <nz-form-label nzRequired>Código de verificação</nz-form-label>
                                    <input nz-input id="codigoVerificacao" 
                                        [dropSpecialCharacters]="true" mask="000-000"
                                        [nzLoading]="codigoVerificacaoLoading"
                                        formControlName="codigoVerificacao" placeholder="Informe o código enviado para o e-mail selecionado" 
                                        (ngModelChange)="validarCodigoVerificacao($event)" />
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="this.parteForm.controls.validado.value">
                <div class="alert alert-secondary mb-4">
                    <div class="row">
                        <div class="col-lg-4 flex-lg-last">
                            <span class="d-block mt-2 mb-3 text-left"><b>Nome Completo</b><br />{{nomeCompletoAdvo}}</span>
                        </div>
                        <div class="col-lg-8 flex-lg-last">
                            <span class="d-block mt-2 mb-3 text-left"><b>E-mail</b><br />{{emailAdvo}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 flex-lg-last">
                            <span class="d-block mt-2 mb-3 text-left"><b>RG</b><br />{{rgAdvo}}</span>
                        </div>
                        <div class="col-lg-4 flex-lg-last">
                            <span class="d-block mt-2 mb-3 text-left"><b>Data de Nascimento</b><br />{{dataNascimentoAdvo | date: 'dd/MM/yyyy'}}</span>
                        </div>
                        
                        <div class="col-lg-4 flex-lg-last">
                            <span class="d-block mt-2 mb-3 text-left"><b>Sexo</b><br />{{sexoAdvo}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="this.parteForm.controls.validado.value">
                <div class="row">
                    <div class="col-lg-4">
                        <div>
                            <nz-form-item>
                                <nz-form-control nzErrorTip="Campo obrigatório">
                                    <nz-form-label nzRequired>Telefone Celular</nz-form-label>
                                    <input nz-input id="telefoneCelular" 
                                        [dropSpecialCharacters]="true" mask="(00) 00000-0000"
                                        formControlName="telefoneCelular" placeholder="Informe o telefone celular"/>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div>
                            <nz-form-item>
                                <nz-form-control>
                                    <nz-form-label>Telefone fixo</nz-form-label>
                                    <input nz-input id="telefoneFixo" 
                                        [dropSpecialCharacters]="true" mask="(00) 0000-0000"
                                        formControlName="telefoneFixo" placeholder="Informe o telefone fixo"/>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="checkbox">
                            <nz-form-item>
                                <nz-form-control nzErrorTip="Campo obrigatório">
                                    <nz-form-label nzRequired>Seccional</nz-form-label>
                                    <nz-select 
                                        id="organizacao"
                                        formControlName="codigoOrganizacao"
                                        nzShowSearch 
                                        nzAllowClear 
                                        nzPlaceHolder="Selecione uma Seccional"
                                        [nzLoading]="organizacoesLoading">
                                    
                                        <nz-option *ngFor="let item of organizacoes$ | async" 
                                            [nzLabel]="item.nome" [nzValue]="item.id">
                                        </nz-option>     
                                    
                                    </nz-select>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="container-termo">
                            <div [innerHTML]="termoCompromisso" class="content-termo"></div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 text-center mt-4">
                        <label nz-checkbox formControlName="aceiteTermoCompromisso" >Li e concordo com o termo de compromisso</label>
                    </div>
                </div>
                <br />
                <div class="row" >
                    <div class="col-lg-12 text-center">
                        <button nz-button nzType="primary" disabled *ngIf="!parteForm.valid
                            || this.parteForm.controls.aceiteTermoCompromisso.value != true">
                            <span>Salvar</span>
                        </button>
                        <!-- [nzLoading]="loading" -->
                        <button nz-button nzType="primary" (click)="cadastrarAdvogado()" 
                        *ngIf="parteForm.valid && this.parteForm.controls.aceiteTermoCompromisso.value == true">
                            <span>Salvar</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="this.parteForm.controls.advogado.value == 0 && this.parteForm.controls.cpf.valid && !loadingVerificacaoCPF"> <!-- Não advogado - Usuario Externo -->
            <div class="row">
                <div class="col-lg-12">
                    <div>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Campo obrigatório">
                                <nz-form-label nzRequired>Nome Completo</nz-form-label>
                                <input nz-input id="nome" 
                                    formControlName="nome" placeholder="Informe o nome completo"/>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <div>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Campo obrigatório">
                                <nz-form-label>RG</nz-form-label>
                                <input nz-input id="rg" maxlength="15"
                                    formControlName="rg" placeholder="Informe o RG"/>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div>
                        <nz-form-item>
                            <nz-form-label nzFor="dataNascimento" nzRequired>Data Nascimento</nz-form-label>
                            <nz-date-picker id="dataNascimento" 
                                formControlName="dataNascimento" 
                                nzFormat="dd/MM/yyyy" 
                                nzPlaceHolder="Informe a data de nascimento"
                                
                                [nzDisabledDate]="disabledDate">
                            </nz-date-picker>
                        </nz-form-item>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Campo obrigatório">
                                <nz-form-label nzRequired>Sexo</nz-form-label>
                                <nz-select nzAllowClear formControlName="sexo" nzPlaceHolder="Selecione o sexo">
                                    <nz-option nzValue="F" nzLabel="Feminino"></nz-option>
                                    <nz-option nzValue="M" nzLabel="Masculino"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Campo obrigatório">
                                <nz-form-label nzRequired>Telefone Celular</nz-form-label>
                                <input nz-input id="telefoneCelular" 
                                    [dropSpecialCharacters]="true" mask="(00) 00000-0000"
                                    formControlName="telefoneCelular" placeholder="Informe o telefone celular"/>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Campo obrigatório">
                                <nz-form-label>Telefone fixo</nz-form-label>
                                <input nz-input id="telefoneFixo" 
                                    [dropSpecialCharacters]="true" mask="(00) 0000-0000"
                                    formControlName="telefoneFixo" placeholder="Informe o telefone fixo"/>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="checkbox">
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Campo obrigatório">
                                <nz-form-label nzRequired>E-mail</nz-form-label>
                                <input nz-input id="email" ngModel email="true"
                                    formControlName="email" placeholder="Informe o E-mail"/>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div>
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Campo obrigatório">
                                <nz-form-label nzRequired>Confirmação E-mail</nz-form-label>
                                <input nz-input id="emailConfirmacao" ngModel email="true" (paste)="removeCtrlV($event, control)"
                                    formControlName="emailConfirmacao" placeholder="Confirme o E-mail"/>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="checkbox">
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Campo obrigatório">
                                <nz-form-label nzRequired>Seccional</nz-form-label>
                                <nz-select 
                                    id="organizacao"
                                    formControlName="codigoOrganizacao"
                                    nzShowSearch 
                                    nzAllowClear 
                                    nzPlaceHolder="Selecione uma Seccional"
                                    [nzLoading]="organizacoesLoading">
                                
                                    <nz-option *ngFor="let item of organizacoes$ | async" 
                                        [nzLabel]="item.nome" [nzValue]="item.id">
                                    </nz-option>     
                                
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <nz-form-item>
                        <nz-form-label nzRequired nzFor="arquivoCPF">Documento com CPF e RG.</nz-form-label>
                        
                        <nz-alert
                            nzShowIcon
                            nzType="info"
                            nzMessage=""
                            [nzDescription]="descriptionCPF">
                        </nz-alert>
                        <ng-template #descriptionCPF>
                            <div class="row">
                                <div class="col-lg-12">
                                    <small>Documento de Identificação oficial com foto, que conste o número do CPF. Este documento deve ser salvo em PDF.</small>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12" style="margin-bottom: 27px; text-align: justify;">
                                    <p>
                                        <small><b>Observação: </b>caso o seu documento de identificação não conste o CPF, você deverá fazer a digitalização do Documento de Identificação e do Documento CPF em arquivo único PDF.</small>
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 text-right">
                                    <span nz-popover nzEllipsis nzType="primary" 
                                        [nzPopoverContent]="contentTemplate" nzPopoverPlacement="bottom">
                                        <button nz-button nzSize="small" nzType="primary" nzGhost>Ver mais</button>
                                    </span>
                                    <ng-template #contentTemplate>
                                        <div class="col-lg-12">
                                            <img src="assets/images/info-documento-com-cpf-sgd.png" alt="Imagem envio documentos.">
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </ng-template>
                    </nz-form-item>
                </div>
                <div class="col-lg-6">
                    <nz-form-item>
                        <nz-form-label nzRequired nzFor="arquivoAutoRetrato">Autoretrato com documento com CPF e RG</nz-form-label>
                        <nz-alert
                            nzShowIcon
                            nzType="info"
                            nzMessage=""
                            [nzDescription]="descriptionAutoRetrato">
                        </nz-alert>
                        <ng-template #descriptionAutoRetrato>
                            <div class="row">
                                <div class="col-lg-12" style="text-align: justify;">
                                    <p>
                                        <small>Autoretrato com Documento de Identificação: é um autoretrato (selfie) da pessoa que se cadastra como usuário externo segurando o documento de identificação com foto, abaixo do rosto, de modo que seja possível visualizar a foto e os dados do documento de identificação. Esta fotografia deve ser salva em PDF.</small>
                                    </p>
                                    <br />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 text-right">
                                    <span nz-popover nzEllipsis nzType="primary" 
                                        [nzPopoverContent]="contentTemplate" nzPopoverPlacement="bottom">
                                        <button nz-button nzSize="small" nzType="primary" nzGhost>Ver mais</button>
                                    </span>
                                    <ng-template #contentTemplate>
                                        <div class="col-lg-12">
                                            <img src="assets/images/documentos-cpf-autoretrato.png" alt="Imagem envio documentos.">
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </ng-template>
                        <!-- <span *ngFor="let item of this.parteForm.value?.arquivos"><span class="ml-3">{{item.name}}<i nz-icon nzType="delete" nzTheme="outline" class="ml-2" (click)="excluirImagemAutoRetrato(item)"  style="color: red;cursor:pointer"></i></span></span> -->
                    </nz-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <input id="arquivoCPF" 
                            type="file" (change)="onFileSelected($event, 'arquivoCPF')"
                            class="pub-campo-arquivo" accept="application/pdf">
                        <span *ngIf="this.parteForm.value?.arquivoCPFPath"><span class="ml-3">{{this.parteForm.value?.arquivoCPFPath}}<i nz-icon nzType="delete" nzTheme="outline" class="ml-2" (click)="excluirImagem('arquivoCPF')"  style="color: red;cursor:pointer"></i></span></span>
                </div>
                <div class="col-lg-6">
                    <input id="arquivo" 
                            type="file" (change)="onFileSelected($event, 'arquivoAutoRetrato')"
                            class="pub-campo-arquivo" accept="application/pdf">
                        <span *ngIf="this.parteForm.value?.arquivoAutoRetratoPath"><span class="ml-3">{{this.parteForm.value?.arquivoAutoRetratoPath}}<i nz-icon nzType="delete" nzTheme="outline" class="ml-2" (click)="excluirImagem('arquivoAutoRetrato')"  style="color: red;cursor:pointer"></i></span></span>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-lg-12">
                    <div class="container-termo">
                        <div [innerHTML]="termoCompromisso" class="content-termo"></div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 text-center mt-4">
                    <label nz-checkbox formControlName="aceiteTermoCompromisso" >Li e concordo com o termo de compromisso</label>
                </div>
            </div>
            <br />
            <div class="row" >
                <div class="col-lg-12 text-center">
                    <button nz-button nzType="primary" disabled *ngIf="!parteForm.valid 
                        || this.parteForm.controls.email.value != this.parteForm.controls.emailConfirmacao.value 
                        || this.parteForm.controls.aceiteTermoCompromisso.value != true">
                        <span>Salvar</span>
                    </button>
                    <!---->
                    <button nz-button nzType="primary" (click)="cadastrarUsuarioExterno()" 
                    [nzLoading]="loading"
                    *ngIf="parteForm.valid 
                        && this.parteForm.controls.email.value == this.parteForm.controls.emailConfirmacao.value 
                        && this.parteForm.controls.aceiteTermoCompromisso.value == true">
                        <span>Salvar</span>
                    </button>
                </div>
            </div>
        </div>      
    </form>
    <!-- <nz-modal 
        [(nzVisible)]="isModalVisible" 
        [nzOkLoading]="isModalLoading"
        nzTitle="" 
        (nzOnCancel)="handleCancel()">
        <img src="https://img.alicdn.com/tfs/TB1g.mWZAL0gK0jSZFtXXXQCXXa-200-200.svg" width="200" height="200" alt="Minha Figura">
    </nz-modal> -->
    <!-- <nz-drawer
      [nzClosable]="false"
      [nzVisible]="isModalVisible"
      nzPlacement="right"
      nzTitle="Basic Drawer"
      [nzContent]="nzDrawerContent"
      (nzOnClose)="handleCancel()">
      <ng-template #nzDrawerContent>
        <img src="https://img.alicdn.com/tfs/TB1g.mWZAL0gK0jSZFtXXXQCXXa-200-200.svg" width="300" height="300" alt="Imagem envio documentos.">
      </ng-template>
    </nz-drawer> -->
</nz-card>

  