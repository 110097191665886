import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutenticacaoRouteService } from './core/guards/autenticacao-route.service';
import { CommonLayoutComponent } from "./core/layouts/common-layout/common-layout.component";
import { CommonLayout_ROUTES } from "./core/routes/common-layout.routes";
import { PublicLayoutComponent } from './core/layouts/public-layout/public-layout.component';
import { PublicLayout_ROUTES } from './core/routes/public-layout.routes';
import { RedirectGuard } from './core/guards/redirect-guard.service';
import { environment } from '../environments/environment';

const appRoutes: Routes = [
    { 
        path: '', 
        component: CommonLayoutComponent,
        children: CommonLayout_ROUTES,
        canActivate: [ AutenticacaoRouteService ]
    },
    { 
        path: 'parte/cadastro', 
        component: PublicLayoutComponent,
        children: PublicLayout_ROUTES
    },
    { 
        path: 'cadastrar', 
        component: PublicLayoutComponent,
        children: PublicLayout_ROUTES
    },
    {
        path: 'assinador',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
          externalUrl: environment.production ? 'https://assinador.oab.org.br/' : 'https://assinador-hom.oab.org.br/'
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { 
            useHash: false,
            scrollPositionRestoration: 'enabled' 
        })
    ],
    exports: [
       RouterModule
    ]
})

export class AppRoutingModule {
}