import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PAGINACAO_PAGE_SIZE } from 'src/app/shared/constantes';
import { Page } from 'src/app/shared/interfaces/page.type';
import { IPagination } from 'src/app/shared/interfaces/pagination.type';
import { Organizacao } from 'src/app/shared/models/organizacao.model';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: "root"
})
export class OrganizacaoService{

  protected readonly apiUrl = `${environment.baseUrl}/organizacao`;

  constructor(protected http: HttpClient) {
  }

  
  consultaPaginada(pageChange:Observable<IPagination>, search?: Observable<{ [key: string]: string }>): Observable<Page<Organizacao[]>> {
      return this.consultaPaginadaPath(pageChange, search, 'consulta');
  }

  consultaPaginadaPath(pageChange:Observable<IPagination>, search: Observable<{ [key: string]: string }>, path: String): Observable<Page<Organizacao[]>> {
      return combineLatest([
          pageChange, search
          ])
      .pipe(
        map(([pageChange, search]) => {    
          let param = search;
              param['page'] = pageChange.pageIndex? `${pageChange.pageIndex-1}` : `${-1}`;
              param['size'] = pageChange.pageSize? `${pageChange.pageSize}` : `${PAGINACAO_PAGE_SIZE}`;
              return param;
            }),
        switchMap(param => this.consultarPath(param, path)));
  }

  consultar(search: { [key: string]: string }): Observable<Page<Organizacao[]>> {
      return this.consultarPath(search, 'consulta');
  }

  consultarPath(search: { [key: string]: string }, path: String): Observable<Page<Organizacao[]>> {
      const params = new HttpParams({ fromObject: search });
      return this.http.get<Page<Organizacao[]>>(`${this.apiUrl}/${path}`, { params });
  }

  listar(query?: { [key: string]: string }): Observable<Organizacao[]> {
      const params = new HttpParams({ fromObject: query });
      return this.http.get<Organizacao[]>(this.apiUrl, { params });
  }

  listarSetores(idOrganizacao, query?: { [key: string]: string }): Observable<Organizacao[]> {
    const params = new HttpParams({ fromObject: query });
    return this.http.get<Organizacao[]>(`${this.apiUrl}/${idOrganizacao}/setores`, { params });
}

  listarPorTipoConselhoSeccional(query?: { [key: string]: string }): Observable<Organizacao[]> {
    const params = new HttpParams({ fromObject: query });
    return this.http.get<Organizacao[]>(`${this.apiUrl}/listarPorTipoConselhoSeccional`, { params });
  }
}
