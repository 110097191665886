import { SideNavInterface } from '../../shared/interfaces/side-nav.type';

export const MENU_CONFIG: SideNavInterface[] = [
    {
        path: '/meus-dados',
        title: 'Meus dados',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user',
        newBlank: false,
        submenu: []
    },
    {
        path: '/consulta/processos',
        title: 'Meus processos',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'folder-open',
        newBlank: false,
        submenu: []
    },
    {
        path: '/diligencia/listar',
        title: 'Responder diligências',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'form',
        newBlank: false,
        submenu: []
    },
    {
        path: '/assinador',
        title: 'Bloco de assinaturas',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'link',
        newBlank: true,
        submenu: []
    }
]    