import { Injectable } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: "root"
})
export class CodigoVerificadorService {

  constructor() {

  }

  static isValid() {
    return (control: AbstractControl): Validators => {
      const codigo = control.value;
      if (codigo.length < 6) {
        return null;
      }
    }
  }
}