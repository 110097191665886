import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/core/services/abstract/crud.service';
import { Parte } from 'src/app/shared/models/parte.model';
import { ParteProcessoResponse } from 'src/app/shared/models/parte-processo.response.model';

@Injectable({
    providedIn: "root"
})
export class ParteService extends CrudService<Parte>{

    constructor(protected http: HttpClient) {
        super(http, "parte");
    }

    cadastrarAdvogado(body: FormData): Observable<ParteProcessoResponse> {
        return this.http.post<any>(`${this.apiUrl}/cadastrar-advogado/`, body);
    }

    cadastrarExterno(body: FormData): Observable<ParteProcessoResponse> {
        return this.http.post<any>(`${this.apiUrl}/cadastrar/`, body);
    }

    atualizarParteCPFExistente(cpf: string, isAdvogado: boolean): Observable<Boolean> {
        return this.http.patch<Boolean>(`${this.apiUrl}/atualizar/cpf/${cpf}/advogado/${isAdvogado}`,null);
    }
}