export const TERMO_COMPROMISSO = `
<center>TERMO DE FORNECIMENTO DE CONSENTIMENTO PARA TRATAMENTO DE DADOS PESSOAIS</center></br>

<div align="justify">
    Em conformidade com o previsto na Lei nº 13.709, de 2018, Lei Geral de Proteção de Dados Pessoais - LGPD, o advogado/ usuário externo, doravante denominado Titular, registra sua manifestação livre, informada e inequívoca, pelo qual concorda com o tratamento de seus dados pessoais, para finalidade determinada, pela Ordem dos Advogados do Brasil – Conselho Federal, inscrita no CNPJ sob o nº 33.205.451/0001-14, com endereço no SAUS Quadra 5, Lote 1, Bloco M, Ed. Sede OAB., Asa Sul, Brasília/DF, CEP 70.070-939, doravante denominado Controlador, para que esta tome decisões referentes ao tratamento de seus dados pessoais, bem como, para que realize o tratamento de tais dados, envolvendo operações como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração, dentre outras.</br>
    </br>
    Cláusula 1ª. Identificação e informações de contato do Controlador:</br>

    1.1.	A Ordem dos Advogados do Brasil – Conselho Federal é a pessoa jurídica a quem compete as decisões referentes ao  tratamento dos dados pessoais do Titular.</br>
    1.2.	O Controlador poderá ser contatado por meio do telefone (61) 2193-9796/9748/9657 e pelo correio eletrônico: rh@oab.org.br.</br>

    </br>
    Cláusula 2ª. Dados pessoais do Titular que serão tratados pelo Controlador:</br>
    2.1. O Controlador fica autorizado a tomar decisões referentes ao tratamento dos seguintes dados pessoais do Titular:</br>
    1.	Nome completo, inclusive o nome social;</br>
    2.	Data de nascimento;</br>
    Cláusula 3ª. Finalidades específicas do tratamento dos dados pessoais do Titular:</br>
    Cláusula 3ª. Finalidades específicas do tratamento dos dados pessoais do Titular:</br>
    3.	Número e imagem do Cartão de Identidade do Advogado(a) – (“OAB”);,
    4.	Número e imagem da Carteira de Identidade (RG);</br>
    5.	Número do Cadastro de Pessoas Físicas (CPF);</br>
    6.	Número e imagem da Carteira Nacional de Habilitação (CNH);</br>
    7.	Fotografia 3x4, inclusive registros fotográficos em eventos corporativos;</br>
    8.	Estado civil;</br>
    9.	Idade;</br>
    10.	Endereço completo;</br>
    11.	Número de telefone residencial e móvel, WhatsApp e endereço de correio eletrônico (e-mail);</br>
    12.	Nome e documentos pessoais dos genitores;</br>
    13.	Nome de usuário e senha específicos para uso dos serviços do Controlador;</br>
    14.	Comunicação mantida entre o(a) Titular e o Controlador;</br>
    15.	Outros documentos ou dados necessários para o cumprimento das finalidades descritas neste termo; </br>

    </br>
    Cláusula 3ª. Finalidades específicas do tratamento dos dados pessoais do Titular:</br>
    3.1. O tratamento dos dados pessoais, listados no presente termo, tem as seguintes finalidades específicas:</br>
    1.	Possibilitar que o Controlador possa fazer a formalização e a criação de acesso para peticionamento, assinatura digital de documentos e visualização de processos dentro do Sistema OAB;</br>
    2.	Possibilitar que o Controlador possa utilizar os dados para fazer comunicações e solicitações eletrônicas através do email, telefone e endereço informado; </br>

    </br>
    Cláusula 4.ª Compartilhamento de dados:</br>
    4.1 - O Controlador fica autorizado a compartilhar os dados pessoais do Titular com outros agentes de tratamento de dados, inclusive órgãos da Administração Pública e autoridades competentes, caso seja necessário para as finalidades listadas no presente termo, observados os princípios e as garantias estabelecidas pela Lei nº 13.709/2018 e também para colaborar na atividade investigatória de denúncias, ou para a condução de medidas e processos judiciais e/ou cumprir obrigações legais e regulatórias.</br>
    4.2 - O Controlador fica autorizado, também, a compartilhar os dados pessoais do Titular com fornecedores de serviços necessários ao desempenho das funções institucionais do Controlador (por exemplo, fornecedores de software de banco de dados, e provedores de acesso à Internet) e em situações que envolverem, consultorias contratadas pelo Controlador;</br>

    </br>
    Cláusula 5ª. Segurança dos dados:</br>

    5.1 - O Controlador se responsabiliza pela adoção de medidas de segurança, técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados, e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.</br>
    5.2 - Em conformidade ao art. 48 da Lei nº 13.709, de 2018, o Controlador comunicará ao Titular e à Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência de incidente de segurança que possa acarretar risco ou dano relevante ao Titular.</br>
    5.3 - Fica informado que o processo disciplinar tramita em caráter sigiloso, somente tendo acesso às suas informações as partes, seus defensores e a autoridade judiciária competente (art. 72, § 2º, Lei n.º 8.906/94), e que os dados pessoais estão resguardados pela Lei n.º 13.709/2018 (Lei Geral de Proteção de Dados Pessoais - LGPD), podendo configurar ilícito penal, cível e administrativo a divulgação das informações e/ou peças do processo ou a permissão de acesso a pessoa não autorizada, e que todo e qualquer acesso ao sistema será registrado e identificado o usuário cadastrado.</br>

    </br>
    Cláusula 6ª. Tempo de Guarda e Término do tratamento dos dados:</br>

    6.1 - O Controlador manterá os dados pessoais do Titular: (i) pelo tempo exigido por lei; (ii) até o término previsto do tratamento de dados pessoais; (iii) pelo tempo necessário a preservar o legítimo interesse do Controlador, conforme o caso; (iv) pelo tempo necessário para resguardar o exercício regular de direitos do Controlador em processo judicial, administrativo ou arbitral. Nesses contextos, os dados serão tratados, por exemplo, durante os prazos prescricionais aplicáveis ou enquanto necessário para cumprimento de obrigação legal ou regulatória.</br>
    6.2 -O Controlador também manterá e tratará os dados pessoais do Titular durante todo o período em que estes forem pertinentes ao alcance das finalidades listadas no presente termo, sendo que os dados pessoais anonimizados, sem possibilidade de associação ao Titular, poderão ser mantidos por período indefinido.</br>
    6.3. O término do tratamento de dados pessoais ocorrerá nos seguintes casos: </br>

    6.3.1 - Quando a finalidade pela qual os dados pessoais do titular foram coletados for alcançada e/ou os dados pessoais coletados deixarem de ser necessários ou pertinentes ao alcance de tal finalidade; </br>
    6.3.2 - Quando o titular dos dados pessoais estiver em seu direito de solicitar o término do tratamento e a exclusão de seus dados pessoais e o fizer; e</br> 
    6.3.3 - Quando houver uma determinação legal neste sentido. </br>
    6.3.4 – Nos casos de término de tratamento de dados pessoais, ressalvadas as hipóteses estabelecidas pela legislação aplicável ou pelo Termo (nas quais poderá haver o bloqueio do acesso aos dados pessoais ainda mantidos), os dados pessoais serão eliminados.</br>

    6.4 - O Titular poderá solicitar ao Controlador, a qualquer momento, por meio de correio eletrônico (e-mail) ou por correspondência, que sejam eliminados seus dados pessoais não anonimizados. Desde já, o Titular se declara ciente de que poderá ser inviável ao Controlador continuar lhe mantendo contato, ou lhe encaminhar mensagens e correspondências a partir da eliminação dos dados pessoais.</br>

    </br>
    Cláusula 7ª. Direitos do Titular:</br>
    7.1. O Titular tem direito a obter do Controlador, em relação aos dados por ele tratados, a qualquer momento, e mediante  requisição:</br>
    1.	Confirmação da existência de tratamento;</br>
    2.	Acesso aos dados;</br>
    3.	Correção de dados incompletos, inexatos ou desatualizados;</br>
    4.	Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei nº 13.709, de 2018;</br>
    5.	Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</br>
    6.	Eliminação dos dados pessoais tratados com o consentimento do) Titular, exceto nas hipóteses previstas no art. 16 da Lei nº 13.709, de 2018;</br>
    7.	Informação das entidades públicas e privadas com as quais o Controlador realizou uso compartilhado de dados;</br>
    8.	Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</br>
    9.	Revogação do consentimento, nos termos do § 5º do art. 8º da Lei nº 13.709, de 2018.</br>
    10.	Determinadas situações podem não autorizar o exercício de alguns direitos previstos acima, e nestas hipóteses o titular/colaborador será informado.</br>

    </br>
    Cláusula 8ª. Direito de revogação do consentimento:</br>
    9.1. O presente consentimento poderá ser revogado a qualquer momento pelo Titular, quando se trate de dados pessoais cujo tratamento se encontre sujeito a obtenção de consentimento, mediante sua manifestação expressa, por meio de solicitação via correio eletrônico (e-mail) ou por correspondência encaminhada ao Controlador, ratificados os tratamentos realizados sob amparo do presente consentimento, nos termos do inciso VI do “caput” do art. 18 da Lei nº 13.709, de 2018.</br>
</div>
`