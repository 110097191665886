import { environment } from "src/environments/environment";

export const SEGURANCA_URL_NEW = `https://${environment.production ? 'seguranca':'seguranca-hom'}.oab.org.br/`;
export const SEGURANCA_URL_OLD = `https://${environment.production ? 'www1':'homnet'}.oab.org.br/`;
export const SEGURANCA_REDIRECT_URL_NEW = `https://${environment.production ? 'seguranca':'seguranca-hom'}.oab.org.br/pages/login?callbackUrl=${btoa(window.location.href)}`;
export const SEGURANCA_REDIRECT_URL_OLD = `https://${environment.production ? 'www1' : 'homnet'}.oab.org.br/Login/?aplicacao=52&ReturnUrl=${window.location.href}`;
export const SEGURANCA_REDIRECT_MUDA_SETOR_URL = `https://${environment.production ? 'www1' : 'homnet'}.oab.org.br/login/changedepartment.aspx?redirect=${window.location.href}`;
export const CS_ORGANIZACIONAL_URL = `${environment.production ? 'https':'http'}://cs-organizacional${environment.production ? '':'-hom'}.oab.org.br/api/membro/photo`;
export const VISUALIZADOR_PROCESSO_URL = `${environment.production ? 'https':'http'}://documento${environment.production ? '':'-hom'}.oab.org.br/arquivos/`;
export const VISUALIZADOR_DOCUMENTO_URL = `${environment.production ? 'https':'http'}://documento${environment.production ? '':'-hom'}.oab.org.br/api/v1/documento/arquivo/`;
export const PAGINACAO_PAGE_SIZE = 20;
export const MSG_ERROR_USUARIO_NAO_LIBERADO = 'Seu cadastro não foi liberado por sua seccional para utilização do sistema de Peticionamento Eletrônico.';