import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/core/services/abstract/crud.service';
import { Parte } from 'src/app/shared/models/parte.model';
import { Advogado } from 'src/app/shared/models/advogado.model';
import { ValidacaoDuasEtapasAdvogado } from 'src/app/shared/models/validacao-duas-etapas-advogado.model';

@Injectable({
    providedIn: "root"
})
export class AdvogadoService extends CrudService<Advogado>{

    constructor(protected http: HttpClient) {
        super(http,"advogado");
    }

    recuperar(cpf: string): Observable<Advogado> {
        return this.http.get<Advogado>(`${this.apiUrl}/recuperar/${cpf}`);
    }

    iniciarValidacaoDuasEtapas(email: string, cpf: string){
        return this.http.get<ValidacaoDuasEtapasAdvogado>(`${this.apiUrl}/iniciar-validacao-advogado-duas-etapas/${email}/${cpf}`);
    }

    validarCodigoInformado(body: FormData): Observable<ValidacaoDuasEtapasAdvogado> {
        return this.http.post<any>(`${this.apiUrl}/validar-codigo-informado/`, body);
    }

    recuperarDadosAdvogado(body: FormData): Observable<Advogado>{
        return this.http.post<any>(`${this.apiUrl}/recuperar-dados-advogado/`, body);
    }
}