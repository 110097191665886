import { Routes } from '@angular/router';
import { AutenticacaoRouteService } from '../guards/autenticacao-route.service';
import { ConsultaProcessosComponent } from 'src/app/module/interno/parte/consulta-processos/consulta-processos.component';


export const CommonLayout_ROUTES: Routes = [
    { 
        path: '', 
        loadChildren: () => import('../../module/interno/parte/parte.module').then(m => m.ParteModule)
    },
    { 
        path: 'parte', 
        loadChildren: () => import('../../module/interno/parte/parte.module').then(m => m.ParteModule)
    }
];